/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

// @font-face {
//     font-family: Rene-Bieder-Light;
//     src: url("../fonts/rene-bieder-light.otf") format("opentype");
//     font-weight: lighter;
//     font-style: normal;
// }


$body-max-width:                    1175px;


//
// General: Color
// 

$brand-color:                       #EC6E27;  
$brand-color-secondary:             #32863B;
$primary-background-color:          #fff;

$brand-color-btn-primary:           #EC6E27;
$brand-color-btn-primary-hover:     #32863B;    
$brand-color-btn-secondary:         #FFF; 
$brand-color-btn-secondary-hover:   #FFF;  

$brand-color-tertiary:              #E6E3DA; 

$primary-font-color:                #000; 
$secondary-font-color:              $brand-color-secondary; 
$h1-font-color:                     $primary-font-color; 
$h2-font-color:                     #000;  
  
$link-color:                        #EC6E27;
$link-color-hover:                  #32863B;   
 
//  
// General: Font 
// 
 
$h1-font-size:                      30px;

$h2-font-size:                      24px;

$standard-font-face:                'Lato', sans-serif;
$secondary-font-face:               'Lato', sans-serif;    
$bold-font-face:                    'Lato', sans-serif;
$drawer-font:                       'Lato', sans-serif;

// 
// Header
//

$header-background:                 #FFF;
$header-top-background:             #000;  
$header-height:                     135px; 
$header-top-height:                 40px;
$header-main-height:                100px;

$header-tablet-height:              56px; 
$header-phone-height:               56px; 
$header-logo-padding:               15px 15px 15px 20px;    

$header-logo-height:                54px;    
$header-logo-width:                 175px;  

$header-minicart-badge-background:  #000;
$header-minicart-badge-color:       #FFF; 
$header-minicart-badge-border:      unset;
$header-top-link-hover-border:      2px solid #000;
$header-icons-color:                $brand-color-secondary;
$header-search-font-color:          $primary-font-color;
$header-search-underline-color:     $brand-color-secondary;
 
$header-top-font-color:             #CCC;
$header-main-font-color:            #000;
$header-main-font-color-hover:      $brand-color;     
$header-main-nav-background-hover:  #FFF; 

      
// 
// Mobile Drawer Menu
// 
 
$drawer-mobile-background:          #FFF;  


//
// Footer
// 

$footer-background:                 #000; 
$footer-link-color:                 #CCC;
$footer-link-color-hover:           $brand-color; 