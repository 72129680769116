/*
 * Flex UX Framework
 *
 * Filename: faqs.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {

	main {
		// background: #fff;
	}

    .page-header {
        height: 0;
    }

	.page-body {
    	max-width: 600px;
        padding: 12px 12px 50px 12px;
        line-height: 1.5em;

        h1{
            margin-top: 30px;
            margin-bottom: 12px;
            font-family: $bold-font-face;
        }
        p{
            font-family: $secondary-font-face;
        }
	}
}