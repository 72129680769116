/*
 * Flex UX Framework
 *
 * Filename: item-images.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

	#featured-product-image{
        /*border: 1px solid $brand-color-tertiary;*/
    }

    .product-image {

    	display: flex;
    	justify-content: center;
    	align-items: center;
    	// flex: 1;
    	position: relative;
        

        &-container {
            width: 100%;
            background: white;
            padding: 20px; 
            box-sizing: border-box;
            max-height: 520px;
            display: flex;
            border: 1px solid #EBEBEB;
      

            @include mq("tablet", "max"){
                width: 100%;
                /*max-width: 500px;*/
                height: 20%;
            }

            #featured-product-image{

                @include mq("tablet", "max"){
                    @include mq("600-pixles", "min"){
                        margin-right: 100px;
                    }
                }

                    img {
                        max-height: 486px!important;
                        max-width: 486px!important;

                        @include mq("tablet", "max"){
                            max-height: 470px!important;
                            max-width: 470px!important;
                        }
                    }
            }

            .product-thumbnails {
           		width: 75px;
           		display: flex;
        		flex-direction: column;

        		.product-thumbnail {
                    border: 2px solid rgba(226,226,226,0.5);
                    height: 70px;
                    width: 70px;
                    text-align: center;
                    overflow: hidden;

                    border: 1px solid $brand-color; 

        			&.active {
        				 border: 2px solid $brand-color;  
        			}

        			&:first-of-type {
        				box-shadow: none !important;
        			}
        		}
        	}

    	i {
    		&.material-icons {
    			font-size: 30px;
    			color: #9D9D9D;
				position: absolute;
				top: 0;
				left: 30px;
				z-index: 2;

                @include mq("tablet", "max"){
                   display: none;
                }
    		}
    	}
    }
}
