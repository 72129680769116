/*
 * Flex UX Framework
 *
 * Filename: item-options.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product {

	&-options-container {
    	position: relative;
    	box-sizing: border-box;
    	border: 1px solid #EBEBEB;
    	padding: 20px;
    	margin-top: 30px;
		color: #333;

    	@include mq("tablet", "max"){
    		padding: 10px;
    	}

		.mdl-menu__container{
			z-index: 1;
		}

    }

    &-quantity-details {
    	display: flex;
    	align-items: center;

    	.product-quantity {
    		min-width: 60px;
			width: 60px;
			margin-right: 25px;

			form {
				display: flex;
				align-items: center;

				&:before {
					content: "QTY: ";
					margin-right: 5px;
					font-size: 14px;
					font-weight: bold;
				}
			}
    	}
	}

	&-image-option{
		&-title{
		    text-transform: uppercase;
		    font-family: $standard-font-face;
		    font-size: 15px;
		    margin-bottom: 10px;
	    	font-weight: 600;
		}

		&-name{
			color: $brand-color;
			font-weight: 600;
			text-transform: uppercase;
		}

		&-thumbnails {
		    display: flex;
		    margin-left: -5px;
		    flex-wrap: wrap;
		}

		&-thumbnail{
			height: 70px;
		    width: 70px;
		    margin: 5px;
		    text-align: center;
		    cursor: pointer;
			outline: 2px solid #F7F7F7;

			&.active, &:hover {
		    	outline: 2px solid $brand-color;
			}
		}
	}

	&-select-options-container{
	    .mdl-menu__container {
	    	.select-option-menu{
	        li{
	            height: 36px;
	            display: flex;
	            align-items: center;
	            &:hover{
	                    color: $brand-color;
	                    text-decoration: none;
	                	background-color: unset; 
	                
	            }
	                color: $primary-font-color;
	                text-transform: none;
	                text-decoration: none;
	                font-family: $secondary-font-face;
	                font-size: 14px;
	                font-weight: 300;
					border-bottom: 1px solid #EBEBEB;
					&:last-of-type {
						border-bottom: none;
					}
	        }
	    }
	    }
	}
}

.product-quantity-details  {
	.product-quantity{ 
		form{
    		margin-bottom: 0;
			input{
		    	font-size: 13px;
		    	font-family: $standard-font-face;
		    	color: #000;
		    	width: 40px;
			}	
		} 

		.mdl-textfield.is-dirty {
		    color: #333;
		}

		.mdl-textfield {
		    color: #BFBFBF;
		    input{
				color: #333;
		    	font-weight: 600;
		    }
		}

		.mdl-textfield.is-dirty .mdl-textfield__label {
		    color: $brand-color;
		   
		}
	}

	.quantity-available {
	    font-weight: 600;
	    margin-left: 20px; 
	}

	.remove-btn {
	    margin-left: auto;
	    font-size: 11px;
	    letter-spacing: 2px;
	}

}
