/*
 * Flex UX Framework
 *
 * Filename: item-add-buttons.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.add-another-btn-container {
    padding: 10px 0;

    .add-another-btn {
        font-size: 15px; 
        color: #333;
        font-family: $standard-font-face;
        border-radius: 3px;
        font-weight: 600;
        &:hover {
            background-color: #F4F5F4;
        }
        .material-icons.add-icon{
            font-size: 24px;
            color: $brand-color-secondary; 
            padding-bottom: 5px;
        }
    }
}

.add-to-list-buttons {
    padding: 30px 0 20px;
    border: 1px solid rgba(226,226,226,0.5);
    border-left: none;
    border-right: none;
    display: flex;


    #add-to-cart-form{
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        @include mq("desktop", "max") {
            align-items: flex-start;
            flex-direction:column;
        }

        @include mq("tablet", "max") {
            justify-content: center;
            flex-direction:row;
        }

        button{
            min-width: 130px;
            margin: 4px;
        }
    }
}

.add-to-wishlist-btn, 
.add-to-cart-btn {
    height: auto;
    min-height: 45px;
    margin-bottom: 10px;
    @include mq("phone-wide", "max") {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
    }
}