/*
 * Flex UX Framework
 *
 * Filename: category-sub-list.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.sub-categories-container-desktop {
    width: 215px;
    min-width: 215px;
    order: 3;
//    border-radius: 5px;
    margin-right: 25px;
    z-index: 1;

    .sub-categories-header {
    	display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: 0 22px;
		height: 59px;
		line-height: 62px;
		background: #FFF;
		border: 1px solid #EBEBEB;

		i {
			color: $brand-color-secondary;
			margin-right: 11px; 
		}

		.sub-categories-title {
			text-transform: uppercase;
			font-size: 15px; 
			font-family: $standard-font-face;
			color: $primary-font-color; 

			span{
				font-weight: 600;
			}
		}
    }

    @include mq('tablet', 'max'){
    	display: none;
    }

}


.sub-categories-content-collapse-wrapper {


	.sub-categories-content {
		min-height: 25px;
    	padding: 12px 21px; 
    	line-height: 28px;
		background: #FFF;
		border-left: 1px solid #EBEBEB;
		border-bottom: 1px solid #EBEBEB;
		border-right: 1px solid #EBEBEB;

    	a {
    		width: 100%;
			display: inline-block;
			text-decoration: none;
			color: $primary-font-color;
			font-size: 14px;
			line-height: 26px; 
			font-family: $secondary-font-face; 
			&:hover {
				color: $brand-color;
			}
    	}
		@include mq('tablet', 'max') {
			border-top: 1px solid #EBEBEB;
		}
	}
	@include mq('tablet', 'max'){
		width: 100%;
	}
}

.sub-categories-header-mobile, 
.sub-categories-content-collapse-wrapper-mobile {


	i {
		color: $brand-color-secondary;
		margin-right: 11px; 
	}

    @include mq('tablet', 'min') {
    	display: none;
    }

}

.sub-categories-header-mobile {
	flex-flow: row nowrap;
    align-items: center;
    background: $primary-background-color;
    align-self: center;
    max-width: 45%;
	border: 1px solid #EBEBEB;

    .sub-categories-title {
    	text-transform: uppercase;
    	font-size: 14px;
    	font-weight: 600;
    	line-height: 12px;
    	color: $header-main-font-color;

    	span {
		    font-weight: 600;
    	}
    }

    &:before {
    	font-family: 'Material Icons';
	    content: '\e896';
	    display: inline-block;
	    font-size: 22px;
	    color: $brand-color-secondary;
	    position: relative;
	    top: 1px;
	    left: -6px;
	    display: none; 
    }

	@include mq('tablet', 'max'){
		display: flex;
	    flex: 0;
	    height: 46px;
	    line-height: 45px;
	    padding: 0 20px 0 15px;
	    cursor: pointer; 
	    margin-top: -59px;
	}
}

.filters-content-collapse-wrapper > .mdl-collapse__content, 
.sub-categories-content-collapse-wrapper > .mdl-collapse__content {
    @include mq("tablet", "min"){
    	margin-top: 0 !important;
    }
}