/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home {
	.spotlight {
		.wrapper{
			height: 363px;
			background-image: url('../images/HOME_desktop_400_1024.jpg');
			background-size: cover;
			background-position-y: 50%;
			box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);

			@include mq("desktop", "max") {
				background-image: url('../images/HOME_tablet_350_768.jpg');
			}

			@include mq("tablet-small", "max") {
				background-image: url('../images/HOME_mobile_300_400.jpg');
			}

			@include mq("phone", "max") {
				background-image: url('../images/HOME_mobile_small_250_321.jpg');
			}

			// @include mq("tablet", "max"){
			// 	height: 35vw;
			// 	min-height: 200px;
			// }

			.content{
				height: 100%;
				display: flex;
				background-image: url('../images/spot-items.png');
				background-repeat: no-repeat;
				background-position: 85%;

				@include mq("tablet-small", "max") {
					background-position: center;
				}

				@include mq("phone-wide", "max") {
					background-image: url('../images/spot-items-mobile.png');
					background-position-y: -75%;
				}


				.text{
					display: flex;
					flex-direction: column;
					padding-top: 40px;
					padding-left: 5.3%;
					@include mq("phone-wide", "max") {
						padding-left: 15px;
						padding-top: 15px;
					}
					@include mq("phone", "max") {
						padding-right: 12%;
					}
					h1{
						color: #FFF;
						font-weight: 400;
						font-size: 55px;
						text-shadow: 0px 11px 20px rgba(0, 0, 0, 0.26);
						text-transform: none;
						margin-top: 40px;
						@include mq("phone", "max") {
							font-size: 45px;
						}
						@include mq("phone-wide", "max") {
							margin: 30px 0 10px 0;
							width: 200px;
						}
					}
					button{
						width: 175px;
						box-shadow: 0 11px 20px rgba(0, 0, 0, 0.26) !important;
					}
			}
		}
	}
 }
}