/*
 * Flex UX Framework
 *
 * Filename: sort-by.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


#sort-by.btn {
    align-self: flex-end;
    padding: 3px 7px;
    padding-right: 10px;
    letter-spacing: 0;
    font-size: 14px;  
    line-height: normal;
    text-transform: uppercase;
    font-weight: 600;
    order: 2;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    color: $primary-font-color;
    font-family: $standard-font-face;  
    border-radius: 0px; 
    // flex-basis: 92px; 
    border: 1px solid #EBEBEB;
 
    @include mq('desktop', 'min') {
        margin-right: 4px;
    }

    @include mq('tablet', 'max') {
    	padding: 5px 10px; 
	    order: 3;
        position: relative;
        bottom: 7px;

        @include mq("phone-wide", "max") {
           // margin-right: 6px;
            padding: 5px 10px;
        }
    }

    @include mq('tablet', 'min') {
    	padding: 5px 15px;
	    min-width: 85px;
	    // height: 41px;
	    
    }

    &::after {
    	font-family: 'Material Icons';
		content: '\e314';
		display: inline-block;
		transform: rotate(-90deg);
		font-size: 22px;
		color: $brand-color-secondary;
		position: relative;
    }

}

.sort-by-container {
    z-index: 1;
    max-width: 150px;
    .mdl-menu__container {
        left:initial !important;
        top:initial !important;
        margin-left: -36px;
        li{
            height: 36px;
            display: flex;
            align-items: center;
            &:hover{
                background-color: unset;
                a{
                    color: $brand-color; 
                    text-decoration: none; 
                }
            }
            a{
                color: $primary-font-color; 
                text-transform: none;
                text-decoration: none;
                font-family:  $secondary-font-face; 
                font-size: 14px; 
                font-weight: 300;
            }
        }
        @include mq('tablet', 'max') {
            margin-left: -61px;
        }
    }
}