/*
 * Flex UX Framework
 *
 * Filename: wishlist-content.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.wishlist{
	 main {
	    background: #fff!important;
	}

	a{
		font-size: 14px;
	}
	h1 {
		font-family: $standard-font-face;
	}
	form{

		&:first-of-type{
			margin: 35px 0px;
		}

		.f-field{
			textarea,input{
				@include mq("phone-wide", "max"){
					width: 100%;
				}
		    &:focus-visible{
		    	outline-color: $brand-color;
		    }
			}
		}
		.f-row, .f-buttons{
			margin-bottom: 10px;
		}
		.f-buttons{
			display: flex;
		}
		 
	}

	.page-body{
		max-width: 700px; 
		p:first-of-type{
			line-height: 18px;
			margin-bottom: 10px;
		}
	}

	.btn-continue-shopping{
		margin-top: 20px;
	}

	.wishlist-grid {
		padding-top: 24px;
		
		@include mq("phone-wide", "max"){
		    padding: 15px;
		}

		textarea {
		    width: 100%;
		    min-height: 64px;
		    min-width: 200px;
		    margin-bottom: 10px;

		    &:focus-visible{
		    	outline-color: $brand-color;
		    }
		}


		.wishlist-product-cell {
			margin-bottom: 50px;

			@include mq('tablet','max'){
				margin-bottom: 10px;
			}
			.product-card{
				@include mq("phone-wide", "max"){
				    width: calc(100% - 28px);
				}
			}

			.mdl-checkbox {
			    float: left;
			    width: 0px;
			}
		}

		.wishlist-controls-cell{

			input{
				margin-top: 10px;
				text-align: center;
				flex-grow: 1;
			}
		}
	}

	.mdl-cell {
		font-size: 14px;

		&.wishlist-controls-cell{
			display: flex;
			justify-content: center;
			@include mq('phone-wide','max'){
				flex-wrap: wrap;
			}

			input{
				margin: 5px 8px 5px 0px;
			}
		}

		@include mq('tablet','max'){
			margin-bottom: 50px;

		}
	}	
}

