/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
 // @TODO: push margin to parent
 // @TODO: Use global variables for font-family (see _configs.scss)
.about-us {
	background: white;
	padding: 29px 0px 0px 0px; 
	max-width: none; 
	text-align: center;
	line-height: 21px;
	margin: 0px 0px 0px 0px;   
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;  
	flex-direction: row;

	@include mq("tablet", "max") {
		flex-direction: column; 
	}

	h1 {
		font-family: $bold-font-face;
	}
	
	p{
		font-family: $secondary-font-face;
		padding: 0px 14px;

		a{
			text-decoration: none;
			white-space: nowrap;
		}
	}

	.copy {
		max-width: 653px;
		margin: 0px;
	}

	.title {
		padding-bottom: 0px;
		margin: 0px;
		margin-bottom: 20px;
		color: #000;
		font-size: 30px;
	}

	button{
		color: white;
		background: rgba(255,255,255, 0.0); 
	}
}
