/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home.v1{
 	main{
	 	.page-body{
	 		max-width: none;
	 		padding: 0;
	 		margin: 0;

				#featured-1{
					h1 {
						text-align: center;
						margin-top: 0;
						margin-bottom: 31px;
						font-family: $bold-font-face;
						color: $primary-font-color;
						@include mq("phone-wide", "max") {
							margin-bottom: 20px;
						}
					}

				.product-list{ 
					margin: 0px;   
					.product-card{
						min-height: 230px;  
						flex-basis: 20%;
						min-width: 180px;
						margin: 0px;
						border: 1px solid #EBEBEB;
						border-right: 0px;
						&:nth-child(n+5), 
						&:nth-child(n+5) {
							@include mq('tablet', 'max'){
							    display: none;
							}
						} 

						@include mq('tablet-small', 'max'){
							min-width: 50%;
							}

						@include mq("desktop-med", "max") { 
							&:nth-of-type(n + 5) { 
								display: none; 
							}
						}

						@include mq("desktop-wide", "min") {   //1374px
                            
                            max-width: none !important; 

					    }
                         
                      
						&:nth-of-type(n + 6) {
							@include mq("tablet", "min"){
                                display: none;  
							}     
						}   

						@include mq("phone-wide", "max"){
							min-width: 160px;
						}

						.product-image{
							flex:inherit;
						}

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						     flex-basis: auto;
						}
					}
				}
 
				button{
					width: 100%;
					max-width: 125px;
					align-self: center;
				}
			} 

			.about-us{
				background: none;
				div.btn--primary{
					display: flex;
				}
			}	
	 	}

 	}
 }