/*
 * Flex UX Framework
 *
 * Filename: home-contact-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home-contact-us {
 	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	background: url("../images/contact-img.jpg");
	background-size: cover;   

    &__content {
		max-width: 948px;
		text-align: center;
		box-sizing: border-box;
		flex: 1 1 100%;
		padding: 24px;
		width: 100%;
		background: rgba(255,255,255, 0.0);
		margin: 25px;
		padding: 15px;

		@include mq("phone-wide", "max"){
	    	margin: 25px 12.5px;
	    }

		.contact-us-grid {
			display: flex;
    		justify-content: center;
    		max-width: 750px;
    		padding: 0px;

    		div{
    			margin: 10px;
    		}
    	}

		h1 {
			margin: 0px;  
			font-family: $standard-font-face;
			color:white;
		}

    	p {
			text-align: center;
			font-family: $secondary-font-face;
			color: white;
			margin-bottom: 10px;

			@include mq("tablet", "max") {
				padding-right: 0px;
			}
		}

		a{
			color: #FFFFFF;

			&:hover{
				color: $link-color-hover;
			}
		}
    }
}