/*
 * Flex UX Framework
 *
 * Filename: footer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.footer {
	display: flex;
	flex-flow: row nowrap;	
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	color: #E6E3DA;
	font-family: $standard-font-face;  
	font-size: 14px;
	font-weight: 600;
	height: 65px;  	
	min-height: 65px;
	padding: 0px 20px 0px 20px;
	background: $footer-background;

	a {
    	text-decoration: none;
    	font-size: 14px;
    	color: $footer-link-color;

		&:hover {
			color: $footer-link-color-hover;
		}	
	}

	.copyright{
		color: #CCC;
	}

	#call-link {
		display: none;
	}

	@media screen and (max-width: 740px) {
		.footer-nav-menu li {
			padding: 0px 8px;
		}
	}
	@media screen and (max-width: 635px) {
		padding: 30px 0px 30px 0px;
		font-size: 16px;
		height: 140px;
		flex-flow: column nowrap;
		justify-content: space-around;
		text-align: center;

		ul.footer-nav-menu {
			max-width: 320px;
			height: 60px;

			li {
				padding: 0px 15px;
			}
		}

		#call-link{
			display: inline-flex;
			
			a {
				display: inline-flex;
				text-decoration: none;
				font-size: 16px;
				

				&::before {
					content: '\e0cd';
					height: 30px;
					width: 18px;
					font-family: "Material Icons";
					font-size: 18px;
					color: #E6E3DA;  
					margin-right: 5px;
				}
			}
		}
	}
}

