/*
 * Flex UX Framework
 *
 * Filename: login-box.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .login {
 	main {
    	background-color: #fff!important;
    	// margin: 0 auto;
    }
 }

.login-box {
    font-family: $secondary-font-face;

    label {
		text-align: left;
	    color: #737677;
    }

    input {
	    text-align: left;
	    font-family: $secondary-font-face;
	    font-size: 14px;
	    color: $primary-font-color;	 

	}

	a {
	   font-size: 14px;
	   
	   &.register, #cancel-btn{
			text-decoration: none;
			font-family: $standard-font-face;
		}
	}

	.mdl-grid {
	    justify-content: center;
	    text-align: center;
	    align-items: center;
	    max-width: 320px;
	    
	    .mdl-cell{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
	    }
	}

	.mdl-textfield {
	    //color: #737677;
 
		&.is-dirty {
		    //color: #333;
		}
		
		&__input {
		    padding-left: 3px;
		    font-family: $secondary-font-face;
		    font-size: 14px;
		}

		&.is-focused,
		&.is-dirty {
			.mdl-textfield__label{
				color: $brand-color-secondary !important;

			}
		}

		.mdl-textfield__label:after {
			background-color: $brand-color-secondary !important;
		}
	}

	.material-icons {
	    font-size: 12px;
	}

	.login-button {
	    font-weight: 700;
	    width: 250px;
	}

	#register-btn {
		width: 250px;
		font-family: $standard-font-face;
		&:active{
			background: $brand-color-secondary;
		}

		&::focus:not(:active){
			background: $brand-color-secondary;
		}
	}
}

.registerBody{
	a{
		text-decoration: none;
	}
}