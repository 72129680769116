/*
 * Flex UX Framework
 *
 * Filename: checkout_multibilltoaddr.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 .checkout-container {
    display: flex;
    flex-wrap: wrap;


  	i {

  		&.material-icons {
  			color: $brand-color-secondary;
  		}
  	}

  	.checkout-main {
  		display: flex;
		flex-flow: column;
    width: 100%;

		@include mq("tablet", "min") {
    		flex: 75 1;
			flex-basis: 75%;
  		}

  		&-top {
  			margin-bottom: 20px;
            background: #fff;
  		}

  		&-bottom {
  			//flex: 1 1 100%;
        background: #fff;
        align-items: center;

        min-height: 120px;

        @include mq("phone-wide", "max") {
            padding-bottom: 10px;  
        }

  		}

      p{
        font-size: 13px;
        line-height: 1.5em;
        font-family: $standard-font-face;
        word-break: break-word;
        white-space: normal;
      }
  	}

  	.checkout-side {
  		flex: 25 1;
		flex-basis: 25%;
        @include mq("tablet", "max") {
          margin-top: 10px;
        }
  		&-top {
  			flex-flow: column;
			   padding: 0;

			&--addresses {
				padding: 20px!important;
				min-height: 125px;

				h2 {
					margin-bottom: 0;
					padding-left: 0;
					padding-right: 0;
					padding-top: 0;
					padding-bottom: 3px !important;
				}
			}
  		}

  		&-bottom {
  			display: flex;
			flex: 1 1 100%;
			flex-flow: column;
			padding: 0;
			margin-top: 20px;

			  @include mq("tablet", "max") {
                align-items: center;
                .mdl-button {
                	min-width: 250px; 
                }
			}


  		}
  	}
}


.checkout-item-caption--stock{
  color: #e60000;
  font-size: 13px;
  font-family: $standard-font-face;
  //margin-top:6px;
}