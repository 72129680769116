/*
 * Flex UX Framework
 *
 * Filename: category.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.category-list {
	main{
		background-color: $primary-background-color; 
	}

	.page-header {
		height: 0px!important;

		@include mq("phone-smallest", "min") {
			padding-top: calc(0px + 0px);
		}		

		@include mq("phone-wide", "min") {
			padding-top: calc(55px + 0px);
		}			

		@include mq("tablet-small", "min") {
			padding-top: calc(55px + 0px);
		}	

	}

	.page-body {
		font-weight: 600; 
		font-size: 13px;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 25px;

		@include mq("tablet-small", "max") {	
			margin-bottom: 12px;
		}

		.products-container {
			flex-grow: 1;
			flex-basis: calc(100% - 260px);
			order: 4;
			margin-right: -12px;
			overflow-x: hidden;

			@include mq('tablet', 'max') {
				margin-right: 0;
    			margin: 12px -12px 0;
			}

			@include mq("phone-wide", "max") {	
				margin: 0px -6px;
				padding: 6px 12px 0px 12px;
			}
		}

		.grid-container {
    		display: flex;
    		flex-flow: row nowrap;
		}

		@include mq("phone-wide", "max") {
		    padding: 12px;
		    margin-top: 0;
		}
	}

	.products-header {
    	flex: 3 75%;
    	order: 1;
		height: 41px;
    	padding-bottom: 5px;
		margin-top: 15px;
    	justify-content: flex-end;
    	
    	@include mq('tablet', 'max') {
			order: -1;
	    	flex: 2 2 100%;
	    	display: flex;
	    	justify-content: flex-end;
		}
		
		@include mq("tablet-small", "max") {
			margin-top: 10px;
		}

    	@include mq('tablet', 'min') {
    		display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			flex: 4 4 100%;
    		margin-bottom: 30px;
    	}

    	.title {
			display: none;
			flex: 1 1 50%;
			font-size: $h2-font-size;
			height: 62px;
			font-weight: 700;
			color: $primary-font-color; 
			font-family: $bold-font-face;
			text-transform: uppercase;

	  		@include mq('desktop', 'max') {
	  			font-size: calc(12px + 2.22vw);
	  		}
			@include mq('tablet', 'min') {
				line-height: 62px;
			}
    	}

	}

	.product-list {
	    &:after {
	        content: "";
	        display: block;

		    @include mq('tablet', 'max') {
		        width: calc(50% - 24px);
		        flex-basis: calc(50% - 24px);
			}

		    position: relative;
		    box-sizing: border-box;
		    overflow: hidden;
		    display: flex;
		    flex-flow: column nowrap;
		    justify-content: space-around;
		    flex-basis: 270px;
		    flex: 1 0 270px;
		    margin: 0px -1px -1px 0px;
		    // padding: 20px;
		    max-width: 240px;
		    /*height: 285px;*/

	  		@include mq('tablet-small', 'min') {
	  			// max-width: 480px;
	  		}
	    }
	}
}

// .sort-by-container{
//     position: relative;
// 	right: 0px;
// 	align-self: flex-end;
// }