/*
 * Flex UX Framework
 *
 * Filename: category-spotlight.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
.item-spotlight {
    border-bottom: 5px solid #06A7E2;
    .page-header {
        @include mq("tablet", "max") {
            margin-top: $header-tablet-height;
            padding-top: 0px !important;  
        }
    }

    .hero-carousel{
        min-height: unset;
        max-height: unset;
        .slick-track{
            .hero-slide {
                height: 200px;
            }
        }
    }

    .text-wrap {
        max-width: 1069px;
        display: flex;
        flex-direction: column;
        align-items: center;


        h2 {  
            font-size: 50px;
            font-family: $standard-font-face;
            text-shadow: 0px 0px 6px black;
            color: #fff;
            @include mq("desktop", "max") {
                font-size: calc(15px + 2.6vw);  
            }
        }

        img {
            height: 76px;  

            @include mq("tablet", "max") {
                &:nth-of-type(1), &:nth-of-type(2) {
                    display: none; 
                }
            }

            &:nth-of-type(2), &:nth-of-type(4) {
                padding-top: 20px; 
            } 

            @media only screen and (max-width: 1085px) {
                height: 69px;   
            }

            @include mq("desktop", "max") {
                height: 61px;   
            }

            @include mq("tablet-small", "max") {   //768px
               height: 51px;  

            }

             @include mq("600-pixels", "max") {
                height: 39px;

                &:nth-of-type(2), &:nth-of-type(4) {
                    padding-top: 10px !important;  
                }  
                
                  
            }

             @include mq("phone", "max") {
                height: 31px; 
            } 

           
             
        }

    }
}