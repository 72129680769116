.home-big-feature {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 6px 0px;

    a {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-decoration: none;
        margin: 24px 14px;
        max-height: 250px;
        width: 47%;
        height: 50vw;
        background-color: $brand-color;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        flex-direction: column;
        border-radius: 3px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

        @include mq("phone", "max"){
            margin: 12px 14px;
        }

        @media screen and (max-width: 933px) {
            width: 95%;
        }

        &:nth-of-type(1) {
            background-image: url("../images/banner-items/banner1-img.jpg");
            background-size: cover;
        }

        &:nth-of-type(2) {
            background-image: url("../images/banner-items/banner2-img.jpg");
            background-size: cover;

        }

        &:nth-of-type(3) {
            background-image: url("../images/banner-items/group_icon.svg");
            background-size: 80px;
            background-position: 200px 40px;

            // @include mq("phone-wide", "max"){
            //     background-image: url("../images/banner-items/mobile-banner3-items.png");
            // }
        }

        h1 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 70px;
            font-weight: 700;
            font-family: $bold-font-face;
            font-size: 40px;
            text-transform: uppercase;
            margin: 0;
            color: white;
            background: #369040;
            opacity: 90%;

            @include mq("phone-wide", "max") {
                font-size: 35px;
            }
        }
        @media only screen and (max-width: 440px) {
            h1 {
                height: 50px;
                font-size: 25px;
            }
          }
    }
}

